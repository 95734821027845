
section.footer{
	background-color: var(--bg-color);
	width: 100%;
	display: flex;
	justify-content: space-between;
	color: var(--white);
	text-transform: capitalize;
	letter-spacing: 1px;
}
.light section.footer{
	background-color: #fff;
	color: var(--black);
}
.light section.footer div svg{
	fill: var(--black);
}
section.footer div svg {
	fill: #fff;
}
.light .info  li  a  span{
	fill: #fff;
}