section.services{
	background-color: var(--bg-second-color);
}
.light section.services{
	background-color: #eee;
}
.services_container{
	display: grid;
    grid-template-columns: repeat(2, 1fr);
	gap: 20px;
}
.services_container > li.service{
	margin: 10px 0;
	display: grid;
	grid-template-columns: 100px auto; 

}

.services_container > li.service .img{
	width: 80px;
	height: 80px;
	box-shadow: 0 0 0px 5px var(--light-font-color),
	0 0 10px 10px rgba(0, 0, 0, 0.2);
	position: relative;
	background-color: var(--bg-second-color);
	position: relative;
	z-index: 1;
	border-radius: 50%;
}
.light .services_container > li.service .img{
	background-color: #eee;
}
.light .services_container .description::before{
	background-color: #eee;
}
.services_container > li.service .img::before{
	content: '';
	position: absolute;
	top: 50%;
	left: 50%;
	border: solid  1px var(--light-font-color);
	transform: translate(-50%, -50%);
	width: 93px;
	height: 93px;
	background-color: transparent;
	border-radius: 50%;	
}
.services_container  .description{
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: -40px;
	padding: 10px 0;
	padding-left: 60px;
	position: relative;
	background-image: linear-gradient(90deg, var(--dark-font-color), var(--bg-second-color) 40%);
	border-radius: 40px;
}
.light .services_container  .description{
	background-image: linear-gradient(90deg, var(--dark-font-color), #eee 40%);
}
.services_container  .description::before{
	content: '';
	position: absolute;
	border-radius: 40px;
	top: 50%;
	transform: translateY(-50%);
	left: 0;
	width: 100%;
	height: 95%;
	background-color: var(--bg-second-color);
}
.services_container .description .title{
	color: var(--light-font-color);
	font-weight: bolder;
	font-size: 17px;
}
.services_container .description .des{
	color: #ccc;
	font-size: 15px;
}