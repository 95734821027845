/*----- loading fonts ------*/
@import './assets/css/fonts.css';
/*  -------------------------- the default config  ---------------------------------*/
*{
	padding: 0;
	margin: 0;
	box-sizing:  border-box;
	transition-property: color, background-color;
	transition-duration: 0.3s;
	scroll-behavior: smooth;
}
html {
    scroll-behavior: smooth;
}

:root{
	--black: #605b5b;
	--white: #ffffff;
	--bg-color: #121631;
	--light-font-color: #319ec8;
	--dark-bg-color : #09001b;
	--dark-font-color: #5c92c1;
	--light-green: #12d7b3;
	--light-yellow: #9ae16c;
	--bg-second-color: #0f1126;
	--light-font-color-gray: gray;
}
html {
  scroll-behavior: smooth;
}
a{
	text-decoration: none;
}
li{
	list-style: none;
}
.gradient-text{
	background-image: linear-gradient(45deg, var(--light-green), var(--light-yellow)) !important;
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}
.img{
	background-size: cover;
	background-position: center;
}
/*  ----------------------------- the header styles -----------------------------  */
body{
	background-color: var(--bg-color);
	font-family: panfram;
}
body.light{
	background-color: #fff;
}
.container{
	padding: 0 145px;
}
section.container{
	padding: 50px 145px;
}
.mouseeffect{
    width: 1px;
    height: 1px;
    box-shadow: 0 0 80px 30px rgba(255, 255, 255, 0.3);
    z-index: 1000;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    position: fixed;
	margin-top: 0 !important;
}
.skeleton{
	animation:  animate-skeleton 1.5s linear infinite alternate ;
}
@keyframes animate-skeleton{
	0%{background-color: #121631;}
	100%{background-color: #5c92c1;}
}
.skeleton-text{
	width: 100%;
	height: 20px;
	border-radius: 3px;
	display: block;
	margin: 0 auto;
}
.skeleton-text-last{
	width: 75%;
}
.skeleton-title{
	width: 75%;
}
.skeleton-text-project-des{
	margin: 3px 0;
	height: 15px;
}
