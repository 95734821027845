/*------------------------------- start the site header stye ---------------------------*/
.siteHeader{
	margin-top: 100px;
	width: 100%;
	height: 500px;
	overflow: hidden;
	display: flex;
}
body.light .siteHeader{
	background-color: #fff ;
}
div#particles-js,
div#tsparticles {
    margin-top: 0;
}
.des-header{
	display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	width: 50%;
}
.vectorContainer{
	width: 50%;
	text-align: center;
}
.vectorContainer > svg{
	width: 55%;
	margin-top: -100px;
}
.des-header > div:nth-child(3){
	max-width: 600px;
}
.des-header .title{
	font-size: 70px !important;
	margin-bottom: 0 !important;
}
.des-header p{
	margin-top: -15px !important;
	margin-bottom: 50px;
	font-size: 22px;
}
.des-header div{
	color: #fff;
	text-align: left;
} 
.light .des-header div{
	color: #6d6d6d;
} 
#root > div:nth-child(3) > div{
	height: auto !important;
}


.vectorContainer > svg:nth-child(1) > g:nth-child(2) > path:nth-child(56),
.vectorContainer > svg:nth-child(1) > g:nth-child(2) > path:nth-child(56),
.vectorContainer > svg:nth-child(1) > g:nth-child(2) > path:nth-child(72),
.vectorContainer > svg:nth-child(1) > g:nth-child(2) > path:nth-child(55){
	/* animation: move-up-down linear infinite 2.5s; */
}
@keyframes move-up-down{
	0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-20px);
    }
    100%{
        transform: translateY(0);
    }
}