@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");



.pagenation-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    background: #fff;
    margin-top: 0 !important;
    margin-bottom: 50px;
}
.button {
    border: none;
}
.button i {
    pointer-events: none;   
}
.button:disabled {
    color: #b3b3b3;
    pointer-events: none;
}
.button,
.link {
    text-align: center;
    line-height: 45px;    
    height: 45px;
    width: 45px;
    font-size: 20px;
    color: #666666;
    background-color: #f2f2f2;
    border-radius: 6px;
    margin: 0 6px;
    cursor: pointer;
    display: inline-block;
    transition: all 0.5s;
}
.links {
    column-gap: 12px;   
}
.link {
    font-weight: 500;
    text-decoration: none;
}
.button:hover,
.link:hover {
    color: #fff;
    background: linear-gradient(45deg, var(--light-green), var(--light-yellow))
}
.link.active {
    color: #fff;
    background: linear-gradient(45deg, var(--light-green), var(--light-yellow))
}
  